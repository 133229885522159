import React from "react";
import { graphql } from "gatsby";
import { Container } from "@util/standard";
import styled from "styled-components";
import BlocksContent from "@components/shared/sub/blockContent";
import LayOut from "@components/layout";
import { TABLET_BREAKPOINT, icons, LAPTOP_BREAKPOINT } from "@util/constants";
import Image from "@components/shared/sub/image";
import { Query } from "@graphql-types";

const Wrapper = styled.div`
  width: 100%;
  margin: 0 auto;
  color: #505050;

  h2 {
    color: #505050;
  }

  h3 {
    color: #505050;
  }
  h5 {
    font-size: 16px;
  }
  p {
    line-height: 20px;
    margin: 0px 0px 10px 0px;
    font-size: 17px;
  }

  ol {
    margin-left: 35px;
  }

  li {
    list-style-type: decimal;
    margin-bottom: 8px;
  }

  @media only screen and (min-width: ${TABLET_BREAKPOINT}px) and (max-width: ${LAPTOP_BREAKPOINT - 1}px) {
    margin: 25px auto 0px auto;
  }

  @media only screen and (min-width: ${LAPTOP_BREAKPOINT}px) {
    margin: 100px auto;
  }
`;

const TopSection = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 80%;
  max-width: 1500px;
  margin: 0 auto;

  @media only screen and (max-width: 600px) {
    width: 90%;
    display: block;
  }
`;

const LeftContainer = styled.div`
  display: block;
  width: 25%;

  @media only screen and (max-width: 1050px) {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }

  @media only screen and (max-width: 600px) {
    width: 100%;
    display: block;
  }
`;

const ImageWrapper = styled.div`
  width: 100%;
  padding: 10%;
  background-color: #f2f2f2;
  border-radius: 10px;
  overflow: hidden;
  img {
    object-fit: contain !important; // !important to override the element style
  }
`;

const StyledImg = styled(Image)`
  padding: 80px;
  width: 80% !important;
  margin: auto;
  border-radius: 10px;
  @media only screen and (max-width: 1050px) {
    width: 30% !important;
  }
`;

const DetailsWrapper = styled.div`
  padding: 30px;
  padding-left: 0px;
  @media only screen and (max-width: 1050px) {
    padding-left: 50px;
  }
  @media only screen and (max-width: 600px) {
    padding: 0px;
  }
`;

const WebLink = styled.a`
  text-decoration: none;
  color: #49a9fc;
  width: 100%;
`;

const Details = styled.div`
  display: flex;
  align-items: center;
  margin-top: 20px;
  gap: 15px;
`;

const Icon = styled.img`
  width: 25px;
`;

const StyledP = styled.p`
  font-size: 27px;
  margin: 0 !important;
  flex: 1;
  min-width: 0;
  word-wrap: break-word;
`;

const RightContainer = styled.div`
  margin: 0 auto;
  width: 75%;
  @media only screen and (max-width: 1050px) {
    width: 100%;
  }

  p {
    font-size: 17px;
  }
`;

const TextWrapper = styled.div`
  padding: 50px;
  padding-top: 0px;
  padding-bottom: 0px;

  a {
    display: inline-block;
  }

  @media only screen and (max-width: 1050px) {
    padding: 50px 0px 0px 0px;
  }
`;

interface BlogTemplateProps {
  data: Query;
}

const BlogTemplate = ({ data }: BlogTemplateProps) => {
  const caseStudy = data.allSanityCaseStudies.edges[0].node;

  return (
    <LayOut>
      <Wrapper>
        <TopSection>
          <LeftContainer>
            {caseStudy.headline && (
              <Container display="none" showOnMobile>
                <h1>{caseStudy.headline}</h1>
              </Container>
            )}
            <ImageWrapper>
              {caseStudy.introImage && (
                <StyledImg data={caseStudy.introImage} />
              )}
            </ImageWrapper>
            <DetailsWrapper>
              {caseStudy.companyName && (
                <Details>
                  <Icon
                    src={icons.home}
                    alt="Home Icon"
                    width={25}
                    height={25}
                  />
                  <StyledP>{caseStudy.companyName}</StyledP>
                </Details>
              )}
              {caseStudy.companyLocation && (
                <Details>
                  <Icon
                    src={icons.mapMarker}
                    alt="Map Marker"
                    width={25}
                    height={25}
                  />
                  <StyledP>{caseStudy.companyLocation}</StyledP>
                </Details>
              )}
              {caseStudy.companyStaffAmount && (
                <Details>
                  <Icon
                    src={icons.users}
                    alt="User Icon"
                    width={25}
                    height={25}
                  />
                  <StyledP>{caseStudy.companyStaffAmount}</StyledP>
                </Details>
              )}
              {caseStudy.companyWebsite && (
                <WebLink href={caseStudy.companyWebsite}>
                  <Details>
                    <Icon
                      src={icons.globe}
                      alt="Globe Icon"
                      width={25}
                      height={25}
                    />
                    <StyledP>{caseStudy.companyWebsite}</StyledP>
                  </Details>
                </WebLink>
              )}
            </DetailsWrapper>
          </LeftContainer>
          <RightContainer>
            <TextWrapper>
              {caseStudy.headline && (
                <Container hideOnMobile>
                  <h1>{caseStudy.headline}</h1>
                </Container>
              )}
              {caseStudy.content && (
                <BlocksContent blocks={caseStudy.content._rawColumnContent} />
              )}
            </TextWrapper>
          </RightContainer>
        </TopSection>
      </Wrapper>
    </LayOut>
  );
};

export const query = graphql`
  query singleCaseQuery($slug: String) {
    allSanityCaseStudies(filter: { slug: { current: { eq: $slug } } }) {
      edges {
        node {
          ...sanityCaseStudy
        }
      }
    }
  }
`;

export default BlogTemplate;
